@import "../../utils/commons.scss";

.container {
  width: 100%;
  @extend .flexCol;
  padding: 2rem 0;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 40%;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 0;
  }

  .content {
    width: var(--fixed-width);
    margin: 0 auto;
    z-index: 1;
    min-height: 300px;
    text-align: center;
    @extend .flexCol;

    h1 {
      font-size: 4rem;
      text-transform: uppercase;
      font-weight: 800;
    }
    span {
      width: 50%;
      margin: 0 auto;
      margin-top: 1rem;
      height: 4px;
      background: var(--clr-secondary);
      display: block;
    }
    p {
      font-size: 1.2rem;
      width: 70%;
      margin: 0 auto;
      margin-top: 2rem;
      font-weight: 700;
    }
  }

  @media screen and (max-width: 1000px) {
    .content {
      h1 {
        font-size: 2.5rem;
      }
      p {
        width: 90%;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .content {
      h1 {
        font-size: 2.2rem;
      }
      p {
        width: 100%;
      }
    }
  }
}
