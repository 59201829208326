// breakpoints

$media-xl: 1500px;
$media-lg: 1200px;
$media-md: 976px;
$media-sm: 600px;

.flexRow {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexCol {
  @extend .flexRow;
  flex-direction: column;
}
.justifyBetween {
  justify-content: space-between;
}
.noDecoration {
  text-decoration: none;
  border: none;
  outline: none;
  list-style: none;
}
.verticalSeparator {
  // height: 100%;
  min-height: 2rem;
  width: 1px;
  margin: 0 1rem;
  background: rgba(0, 0, 0, 0.2);
}
.hline {
  width: 100%;
  height: 1px;
  margin: 1rem 0;
  background: rgba(0, 0, 0, 0.1);
}

.loading {
  width: 100%;
  margin-top: 3rem;
  @extend .flexRow;

  svg {
    fill: var(--clr-primary) !important;
    color: var(--clr-primary) !important;
  }
}
